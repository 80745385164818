<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <label for="title">section title</label>
                <text-input
                  id="title"
                  v-model="section.title"
                  autocomplete="off"
                />
                <span v-if="hasError('section.title')" class="form-error">{{ showError('section.title') }}</span>

                <label for="subTitle" class="mt-8">section subtitle</label>
                <text-input
                  id="subTitle"
                  v-model="section.subTitle"
                  autocomplete="off"
                />
                <span v-if="hasError('section.subTitle')" class="form-error">{{ showError('section.subTitle') }}</span>

                <label class="mt-8">columns</label>
                <div class="radio-group mt-4">
                    <div class="radio">
                        <input type="radio" name="columns" id="columnsTwo" :value="2" v-model="section.nbColumns">
                        <label for="columnsTwo">
                            <icon name="columnsTwo" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="columns" id="columnsThree" :value="3" v-model="section.nbColumns">
                        <label for="columnsThree">
                            <icon name="columnsThree" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="columns" id="columnsFour" :value="4" v-model="section.nbColumns">
                        <label for="columnsFour">
                            <icon name="columnsFour" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                </div>
            </div>
            <!-- <div class="form-col">

                <toggle-buttons v-model="section.nbColumns" :options="columns"></toggle-buttons>
            </div> -->
            <div class="form-col">
                <div>
                    <label for="textOne" class="mb-1">1st column text</label>
                    <text-input
                      id="textOne"
                      v-model="section.textOne"
                      inputClass="h-48"
                      multiline
                    />
                    <span v-if="hasError('section.textOne')" class="form-error">{{ showError('section.textOne') }}</span>
                </div>
                <div class="mt-6">
                    <label for="textTwo" class="mb-1">2nd column text</label>
                    <text-input
                      id="textTwo"
                      v-model="section.textTwo"
                      inputClass="h-48"
                      multiline
                    />
                    <span v-if="hasError('section.textTwo')" class="form-error">{{ showError('section.textTwo') }}</span>
                </div>
                <div class="mt-6" v-if="section.nbColumns >= 3">
                    <label for="textThree" class="mb-1">3rd column text</label>
                    <text-input
                      id="textThree"
                      v-model="section.textThree"
                      inputClass="h-48"
                      multiline
                    />
                    <span v-if="hasError('section.textThree')" class="form-error">{{ showError('section.textThree') }}</span>
                </div>
                <div class="mt-6" v-if="section.nbColumns >= 4">
                    <label for="textFour" class="mb-1">4th column text</label>
                    <text-input
                      id="textFour"
                      v-model="section.textFour"
                      inputClass="h-48"
                      multiline
                    />
                    <span v-if="hasError('section.textFour')" class="form-error">{{ showError('section.textFour') }}</span>
                </div>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import ModalNavigation from "@/mixins/ModalNavigation";
// import ToggleButtons from "@/components/ui/ToggleButtons";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Icon from "@/components/ui/Icon";
import TextInput from "@/components/ui/TextInput";

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, Icon, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            section: {
                title: null,
                subTitle: null,
                textOne: null,
                textTwo: null,
                textThree: null,
                textFour: null,
                nbColumns: 2
            },
            columns: [
                { value: 1, label: 'One'},
                { value: 2, label: 'Two'},
                { value: 3, label: 'Three'},
                { value: 4, label: 'Four'},
            ],
            isProcessing: false,
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.isProcessing = true;
                this.$emit('save-page-widget', Object.assign({}, this.section));
            }
        },
    },
    validations: {
        'section.title' : 'required',
        'section.subTitle' : 'required',
        'section.textOne': 'required',
        'section.textTwo': 'required',
        'section.textThree': 'required_if_three_columns_or_more:section.nbColumns',
        'section.textFour': 'required_if_four_columns:section.nbColumns',
    },
    created() {
        this.initValidator();

        if (this.values.length) {
            Object.assign(this.section, {
                title: this.getValue('title'),
                subTitle: this.getValue('subTitle'),
                textOne: this.getValue('textOne'),
                textTwo: this.getValue('textTwo'),
                textThree: this.getValue('textThree'),
                textFour: this.getValue('textFour'),
                nbColumns: this.getValue('nbColumns'),
            });
        }
    },
    mounted() {
        this.validator.extend('required_if_three_columns_or_more', (value, arg) => {
            if( parseInt(arg) < 3)
                return true;

            return (value && parseInt(arg) >= 3);
        });

        this.validator.extend('required_if_four_columns', (value, arg) => {
            if( parseInt(arg) < 4)
                return true;

            return (value && parseInt(arg) === 4);
        });

        this.validator.setCustomErrorMessages({
            'section.textThree.required_if_three_columns_or_more' : 'this field is required.',
            'section.textFour.required_if_four_columns' : 'this field is required.',
        });
    }
}
</script>
<style scoped>
    .radio-group{
        display:flex;
        align-items: center;
    }
    .radio-group .radio + .radio{
        margin-left: 1.5rem;
    }
    .radio label{
        display:flex;
        align-items: center;
    }
    .radio input{
        margin-right: .5rem;
    }
</style>
